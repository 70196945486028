"use strict"
import SimpleAnimation from "./SimpleAnimation";
const tools = require('../tools');
import Circle from './Circle';
import Info from './Info';

class CircularNav {
    constructor(el) {
        this.el = el;
        this.svgEl = this.el.querySelector('.svg-circle');
        this.circles = [];
        this.infos = [];
        this.rotationGap = this.el.getAttribute('data-rg') * 1;
        this.video = this.el.querySelector('video');
        this.anims = [];
        this.animsStack = [];
        this.running = false;
        this.themeElts = [];
    }

    init() {
        if(this.svgEl) {
            this.themeElts.push(this.el);
            this.themeElts.push(document.querySelector('.footer-logo'));
            this.initCircles();
            this.appearingAnim();
        }
    }

    reInit() {
        tools.each(this.infos, i => i.hide());
        this.stackAnimation(new SimpleAnimation(
            this.el,
            el => {
                el.classList.remove('initialized');
                return false;
            }
        ))

        tools.eachReverse(this.circles, circle => {
            let anim = circle.getDisappearAnim();
            if(anim) {
                this.stackAnimation(anim)
            }
        })

        this.animate();
        this.appearingAnim();
        this.showSectionInfo('none')
    }

    initCircles() {
        let circlesGroups = this.svgEl.querySelectorAll('g')

        let clickHandler = circle => {
            if(this.running) {
                return;
            }
            let index = circle.getIndex()

            let anim = circle.getActivationAnim();
            if(anim) {

                if(circle.getTheme()) {
                    tools.each(this.circles, circle => {
                        if(circle.getTheme()) {
                            tools.each(this.themeElts, te => te.classList.remove(circle.getTheme()));
                        }
                    })
                    tools.each(this.themeElts, te => te.classList.add(circle.getTheme()));
                }

                this.stackAnimation(anim);

                if(circle.getVideo() && this.video) {
                    let currentSource = this.video.querySelector('source');
                    if(currentSource.src.indexOf(circle.getVideo()) === -1) {
                        let newVideo = document.createElement('video');
                        newVideo.setAttribute('muted', 'muted');
                        newVideo.setAttribute('autoplay', 'autoplay');
                        newVideo.setAttribute('loop', 'loop');
                        newVideo.setAttribute('playsinline', 'playsinline');
                        let newSource = document.createElement('source');
                        newSource.src = circle.getVideo();
                        newVideo.appendChild(newSource);
                        let videoParent = this.video.parentNode;
                        videoParent.innerHTML = '';
                        videoParent.appendChild(newVideo);
                        this.video = newVideo;
                    }
                }
            }

            tools.each(this.circles, c => {
                let anim = null;
                let baseRad = 30;
                if(c.getIndex() < index) {
                    anim = c.getDeactivationAnim(- baseRad  + 10 - 10 * (index - c.getIndex() - 1))

                } else if (c.getIndex() > index) {
                    anim = c.getDeactivationAnim(baseRad + 10 + 10 * (c.getIndex() - 1 - index))
                }

                if(anim) {
                    this.stackAnimation(anim);
                }
            })

            this.animate();
            this.showSectionInfo(circle.getSection())
        }

        tools.each(circlesGroups, (circleG, i) => {
            let circle = new Circle(circleG, this.rotationGap, i);
            this.circles.push(circle);

            circle.applyDashArray();
            circle.getCircleEl().addEventListener('click', () => clickHandler(circle));
            circle.getTextEl().addEventListener('click', () => clickHandler(circle));
        })

    }

    initInfos() {
        if(!tools.isEditMode()) {
            let infoWrapperEl = document.querySelector('.info-wrapper');
            if(infoWrapperEl) {
                tools.each(infoWrapperEl.querySelectorAll('.info'), el => this.infos.push(new Info(el)));
            }
        }
    }

    showSectionInfo(sectionId) {
        let found = false;
        let toShow = []
        let duration = 0;
        tools.each(this.infos, i => {
            if(i.getSection() === sectionId) {
                toShow.push(i);
            } else {
                duration = Math.max(i.hide(), duration);
            }
        });

        if(toShow.length > 0) {
            found = true;
            let toShowSelected = toShow[Math.floor(Math.random() * toShow.length)];
            toShowSelected.spreadLetters(true);
            setTimeout(()=>{
                toShow[Math.floor(Math.random() * toShow.length)].show();
            }, duration)
        }
        return found;
    }

    appearingAnim() {

        tools.eachReverse(this.circles, circle => {
            let anim = circle.getInitialAnim();
            if(anim) {
                this.stackAnimation(anim)
            }
        })

        this.animate();

        this.stackAnimation(new SimpleAnimation(
            this,
            arg => {
                arg.el.classList.add('initialized');
                arg.initInfos();
                if(arg.infos.length > 0) {
                    arg.showSectionInfo('none');
                }
                return false;
            }
        ))
    }

    stackAnimation(animation) {
        if(this.running) {
            this.animsStack.push(animation);
        } else {
            this.anims.push(animation)
        }
    }

    unstackAnimations() {
        let goAhead = false;
        if(this.animsStack.length > 0) {
            this.anims = this.animsStack;
            this.animsStack = [];
            goAhead = true;
        } else {
            this.anims = []
        }

        return goAhead;
    }

    animate() {
        if(!this.running) {
            this.running = true;
            let loop = () => {
                let oneRunning = false;

                tools.each(this.anims, anim => {
                    anim.iterate();
                    if(anim.isRunning()) {
                        oneRunning = true;
                    }
                });

                if(oneRunning || this.unstackAnimations()){
                    window.requestAnimationFrame(loop);
                } else {
                    this.running = false;
                }
            }

            window.requestAnimationFrame(loop);
        }
    }
}

export default CircularNav;
