import Animation from './Animation';

class SimpleAnimation extends Animation {
    constructor(args, fn, prepareFn = null, doneFn = null) {
        let options = {
            args: args,
            fn: fn,
            prepareFn: prepareFn,
            doneFn: doneFn
        }
        super(options);
    }
}

export default SimpleAnimation;
