import CircularNav from "./CircularNav";

function init() {
        let cnEl = document.querySelector('.circular-nav');
        if(cnEl) {
            let homeNav = new CircularNav(document.querySelector('.circular-nav'));
            homeNav.init();
            window.reInit = ()=> {homeNav.reInit(); return "resetting in progress";}
        }
}


document.addEventListener('DOMContentLoaded', () => {init();});
