import Animation from './Animation';

class AnimationList{
    constructor() {
        this.anims = [];
        this.index = 0;
        this.running = true;
    }

    add(anim=new Animation()) {
        this.anims.push(anim);
    }

    iterate() {
        if(this.running) {
            this.anims[this.index].iterate();
            if(!this.anims[this.index].isRunning()) {
                this.index++;
                this.running = this.index < this.anims.length;
            }
        }
    }

    isRunning() {
        return this.running;
    }
}

export default AnimationList;
