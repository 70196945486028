const tools = require('../tools');

class Info {
    constructor(el) {
        this.el = el;
        let sectionAttribute = el.getAttribute('data-section');
        this.section = sectionAttribute ? sectionAttribute : 'none';
        this.title = el.querySelector('.title');
        this.letters = [];
        this.link = el.querySelector('a');
        this.gap = 20;
        this.translation = 100;
        this.cssTransitionDuration = 400;
        this.visible = false;

        this.init()
    }

    getSection() {return this.section;}

    hasSection() {return this.getSection() !== 'none';}

    isVisible() {return this.visible;}

    getAnimDuration(time=0) {
        return time + this.cssTransitionDuration;
    }

    init() {
        if(this.title && this.structurateLetters()) {
            tools.doOnWindowResize({arg: this, fn: arg => arg.structurateLetters()})
            this.el.classList.add('initialized');
            this.spreadLetters(true);
            this.el.addEventListener('click', e => {
                e.stopPropagation();
                e.preventDefault();
                if(this.link) {
                    this.hide();
                    console.log(this.getAnimDuration())
                    setTimeout(() => {
                        if(this.link) {
                            window.location.href = this.link.href;
                        }
                    }, this.getAnimDuration())
                }

            })
        }
    }

    structurateLetters() {
        let letters = this.title.querySelectorAll('.letter');
        if(letters) {
            let top = -1;
            let line = null;
            tools.each(letters, l => {
                let letterBCR = l.getBoundingClientRect();

                if(letterBCR.top !== top) {
                    top = letterBCR.top;
                    if(line && line.length > 0) {
                        this.letters.push(line);
                    }
                    line = [];
                }
                line.push(l);
            })

            if(line && line.length > 0) {
                this.letters.push(line);
            }

        }
        return this.letters.length > 0;
    }

    spreadLetters(show=true) {
        let px = this.translation * (show ? 1 : -1);
        let translation = 'translateX('+ px + 'px)';
        tools.each(this.letters, line => {
            tools.each(line, letter => {
                letter.style.transform = translation
            })
        })
    }

    getRandom(toValEx) {
        return Math.floor(Math.random() * toValEx) * (Math.random() < 0.49 ? -1 : 1);
    }

    show() {
        let time = 0;

        if(!this.isVisible()) {

            this.visible = true;
            this.el.classList.add('visible');

            tools.each(this.letters, line => {
                let tempTime = 0;
                tools.each(line, letter => {
                    let l = letter;
                    l.style.transitionDelay = tempTime + 'ms';
                    l.classList.add('show');
                    tempTime += this.gap;
                })

                time = Math.max(time, tempTime);
            })

            time = this.getAnimDuration(time);
        }
        return time;
    }

    hide() {
        let time = 0;
        if(this.isVisible()) {
            this.spreadLetters(false);
            this.visible = false;
            tools.each(this.letters, line => {
                let tempTime = 0;
                tools.each(line, letter => {
                    let l = letter;
                    l.style.transitionDelay = tempTime + 'ms';
                    l.classList.remove('show')
                    tempTime += this.gap;
                })
                time = Math.max(time, tempTime);
            });

            time = this.getAnimDuration(time);
            setTimeout(() => {
                this.el.classList.remove('visible');
                // this.spreadLetters(true);
            }, time)
        }
        return time;
    }
}

export default Info;
