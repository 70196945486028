import SimpleAnimation from "./SimpleAnimation";
import AnimationList from "./AnimationList";
class Circle {
    constructor(circleG, rotationGap, index) {
        this.gEl = circleG;
        this.circleEl = circleG.querySelector('circle');
        this.textEl = document.querySelector('#' + circleG.getAttribute('data-text'));
        this.linkEL = this.textEl.querySelector('a');
        this.textAxis = this.textEl.querySelector('.axis');
        this.rotationGap = rotationGap;
        this.theme = circleG.getAttribute('data-theme').trim();
        this.section = circleG.getAttribute('data-section').trim();
        this.video = circleG.getAttribute('data-video');
        this.index = index;
        this.perim = this.circleEl.getAttribute('data-perim') * 1;
        this.angle = -180;
        this.standardAngle = this.circleEl.getAttribute('data-angle') * 1;
        this.targetAngle = 0;
        this.startEndAngle= 0;
        this.dashLength = 0;
        this.subnavVisible = false;
    }

    getGEl() {return this.gEl;}
    getTextEl() {return this.textEl;}
    getCircleEl() {return this.circleEl;}
    getTheme() {return this.theme;}
    getIndex() {return this.index;}
    getSection() {return this.section;}
    getVideo() {return this.video;}

    setTargetAngle(targetAngel) {
        this.targetAngle = targetAngel;
        this.startEndAngle = targetAngel - this.angle;
    }

    reInitAngle() {
         this.angle = this.angle % 360;
         if(this.angle > 0) {
             this.angle -= 360;
         }
    }

    getInitialAnim() {
        return new SimpleAnimation(this,
                arg => arg.progress(15),
                arg => {
                    arg.setTargetAngle(arg.standardAngle);
                    arg.circleEl.setAttribute('class', ''); //IE 11 make me do this
                    arg.textEl.classList.remove('visible');
                },
                arg => arg.textEl.classList.add('visible')

        )
    }

    getDisappearAnim() {
        return new SimpleAnimation(this,
            arg => arg.regress(7),
            arg => {
                arg.setTargetAngle(-180);
                arg.textEl.classList.remove('visible');
            },
            arg => {
                arg.circleEl.classList.add('hidden');
                arg.hideSubNav();
            }
        )
    }

    getActivationAnim() {
        if (this.subnavVisible && this.linkEL) {
            window.location.href = this.linkEL.href;
            return null;
        } else {
            let animList = new AnimationList();
            let rotateAnim = this.getRotateToAnim(0);
            if(rotateAnim) {
                animList.add(rotateAnim);
            }
            animList.add(this.getShowSubNavAnim());
            return animList;
        }
    }

    getDeactivationAnim(targetDeg) {
        let animList = new AnimationList();
        animList.add(this.getHideSubNavAnim());
        let rotateAnim
        if(Math.abs(targetDeg - this.angle) <= 5) {
            rotateAnim = this.getRotateToAnim(targetDeg, 1);
        } else {
            rotateAnim = this.getRotateToAnim(targetDeg);
        }
        if(rotateAnim) {
            animList.add(rotateAnim);
        }

        return animList;
    }

    getShowSubNavAnim() {
        return new SimpleAnimation(this, args => {
            if(!args.subnavVisible) {
                args.showSubNav();
            }
            return false;
        })
    }

    getHideSubNavAnim() {
        return new SimpleAnimation(this, args => {
            if(args.subnavVisible) {
                args.hideSubNav();
            }
            return false
        })
    }


    getRotateToAnim(targetDeg, speed=5, cleanFn=null) {
        if(targetDeg !== this.angle) {
            return new SimpleAnimation(
                this,
                targetDeg <  this.angle?
                    arg => arg.regress(speed):
                    arg => arg.progress(speed),
                arg => arg.setTargetAngle(targetDeg),
                cleanFn
            );
        }
        return null;
    }

    regress(speed=5) {
        let state = true;

        let progression = this.calcMod() * speed;

        this.angle -= progression;
        if(isNaN(this.angle) || this.angle < this.targetAngle) {
            this.angle = this.targetAngle;
            state = false;
        }
        this.draw()

        return state;
    }

    progress(speed=5) {
        let state = true;

        let progression = this.calcMod() * speed;

        this.angle += progression;

        if(isNaN(this.angle) || this.angle > this.targetAngle) {
            this.angle = this.targetAngle;
            state = false;
        }
        this.draw()
        return state;
    }

    calcMod() {
        let mod = (this.targetAngle - this.angle) / (this.startEndAngle);

        if(mod > 1) {
            mod = 1 - mod;
        } else if (mod < 0.01) {
            mod = 0.01;
        }

        return mod;
    }

    draw() {
        this.applyDashArray();
        this.applyTextRotation();
    }

    calcDashLengthFromDeg(deg) {
        return Math.ceil(this.perim / 2  + deg * (this.perim + 1) / 360) ;
    }

    calcDashLength() {
        return Math.ceil(this.perim / 2 - (this.rotationGap / 360 * this.perim) + this.index * (this.rotationGap / 360 * this.perim)) + 1;
    }


    setStrokeWidth(px) {
        this.circleEl.setAttribute('stroke-width', px);
    }

    applyDashArray() {
        this.dashLength = this.calcDashLengthFromDeg(this.angle);
        this.circleEl.setAttribute('stroke-dasharray', this.dashLength + ' ' + (this.perim - this.dashLength));
    }

    applyTextRotation() {
        this.textAxis.style.transform = 'rotateZ(' + this.angle + 'deg)';
    }

    showSubNav() {
        this.subnavVisible = true;
        this.textEl.classList.add('active');
    }

    hideSubNav() {
        this.subnavVisible = false;
        this.textEl.classList.remove('active');
    }
}

export default Circle;
